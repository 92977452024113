<template>

<div id="container">
  <div id="header">
    <admin-header-section />
  </div>
  <div id="main" class="admin ">
    <div class="adminContainer">
      <h4>Admin Options</h4>
      <b-row class="adminOptionRow">
        
        <b-col class="adminOptionRow">
          <router-link to="/admin/payment"><div class="adminholderSquare">
            <div class="adminholderContentholder">
                <div class="adminholderDescptionHolder">View Payments</div>
            </div>
            
          </div></router-link>
        </b-col>
          
        <b-col class="adminOptionRow">
          <div class="adminholderSquare">
            <router-link to="/admin/wine"><div class="adminholderContentholder">
                <div class="adminholderDescptionHolder">Manage Wines ( pos )</div>
            </div></router-link>
            
          </div>
        </b-col>
        <b-col class="adminOptionRow">
          <router-link to="/admin/food"><div class="adminholderSquare">
            <div class="adminholderContentholder">
                <div class="adminholderDescptionHolder">Manage Food ( pos )</div>
            </div>
            
          </div></router-link>
        </b-col>
        <b-col class="adminOptionRow">
          <router-link to="/admin/user"><div class="adminholderSquare">
            <div class="adminholderContentholder">
                <div class="adminholderDescptionHolder">Manage Users</div>
            </div>
            
          </div></router-link>
        </b-col>

      </b-row>
    </div>
  </div>  
</div>

</template>


<script>

import AdminHeaderSection from './AdminHeaderSection.vue';
// import { HTTP } from './plugins/http-common';


export default {
  name: 'Admin',
  components:{
    AdminHeaderSection,
  },
  data () {  
      return {
      };
  },
  methods: { 
  }  
}      


</script>

<style>

  body {
    font-family: Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    background-color: #131720;
    margin: 0px;
    padding: 0px;
    font-size: 0.8rem;
    overflow: hidden;     
    border:5px solid #131720;
  }

  .admin {
    color:white;
    width:100%;
  }
  .adminContainer {
    height: calc(100%);
    width: 90%;
    margin:auto;
  }
  .adminContainer h4 {
    color:white;
  }
  .adminholderSquare {
    position: relative;
    width: 100%;
    border-radius: 10px;
    background-color: #2e3444;
    height: 20vh !important;
    overflow: hidden;
  }

  .adminholderSquare:after {
    content: "";
    display: block;
    padding-bottom: 100%;
    background-color: #2e3444;    
    margin:30px;
  }

  .adminholderContentholder {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .adminholderDescptionHolder {
    border: 1vw solid transparent;
    min-height: 20vh;
    font-size: 1.6vw;
    font-weight: 700;
    line-height: 1.6vw;   
  }
  .adminOptionRow {
    height: 20vh !important;

  }
  .adminOptionRow a {
    color:white;
  }

</style>